var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": "배송선택"
    }
  }), _c('v-select', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "placeholder": "선택",
      "return-object": "",
      "item-value": "_id"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.shippingOption,
      callback: function ($$v) {
        _vm.shippingOption = $$v;
      },
      expression: "shippingOption"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    items: _vm.items
  }), false)), _c('div', {
    staticClass: "txt txt--xs mt-12px"
  }, [_vm._v(" *상품의 부피와 무게에 따라 배송요금이 부과되며 해당 택배사의 요금기준을 따릅니다."), _c('br'), _vm._v(" *제주, 도서산간은 추가배송요금이 부과되며 해당 택배사의 요금기준을 따릅니다.(자사몰 공통) >> 가격 13,000원 ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }